import React, { useEffect } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuLink from './MenuLink'
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'

// import theme from '../theme'
import { makeStyles } from '@material-ui/styles'
// for menu elevation: sombrita cuando scrolleas
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { useAuth0 } from '@auth0/auth0-react'


const useStyles = makeStyles((theme) => ({
  logo: {
    display: "flex",
    alignItems: "center",
    gap: 10
  },
  toolbar: {
    textDecoration: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  userPanel: {
    display: 'flex',
    gap: 15
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 1.4,
    textAlign: 'right',
  }
}))


function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  // window: PropTypes.func,
};

const AuthNav = () => {
  const { loginWithRedirect, logout, isLoading, isAuthenticated, user } = useAuth0()
  const classes = useStyles();
  console.log('isAuthenticated:', isAuthenticated)
  console.log('isLoading:', isLoading)
  console.log('user:', user)
  if (!isLoading) {
    if (!isAuthenticated) {
      return (
        <Button variant="contained" color="secondary" onClick={() => loginWithRedirect({ appState: { targetUrl: window.location.pathname } })}>Iniciar Sesión</Button>
      )
    } else {
      return (
        <div className={classes.userPanel}>
          <div className={classes.userInfo}>
            <span style={{ fontWeight: 'bold' }}>{user.nickname}</span>
            <span>{user.given_name} {user.family_name}</span>
          </div>
          <Button variant="contained" color="secondary" onClick={() => logout({ returnTo: window.location.origin })}>Cerrar Sesión</Button>
        </div>
      )
    }
  } else {
    return (
      <p style={{ color: "white" }}>Cargando...</p>
    )
  }
}

const Navbar = (props) => {
  const { loginWithRedirect, logout, isLoading, isAuthenticated, user } = useAuth0()
  useEffect(() => {
    console.log('isAuthenticated:', isAuthenticated)
    console.log('isLoading:', isLoading)
    console.log('user:', user)
  })
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  };

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );


  return (
    <div className={classes.grow}>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar >
          <Toolbar>
            <Grid container justify="space-between" alignItems="center" >
              <Grid item className={classes.logo}>
                <img src={process.env.PUBLIC_URL + "/logo192.png"} alt="SAS Logo" style={{ height: 50 }} />
                <Typography variant="h4" style={{ fontFamily: "Source Serif Pro, serif" }} >SAS</Typography>
              </Grid>
              <Grid item>
                {/* <MenuLink to="/" variant="contained" color="secondary">Home</MenuLink>
                <MenuLink to="/siniestros" variant="contained" color="secondary">Siniestros</MenuLink> */}
              </Grid>
              <Grid item>
                <AuthNav />
                {/* <div className={classes.sectionDesktop}>
                  <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="error">
                      <MailIcon />
                    </Badge>
                  </IconButton>
                  <IconButton aria-label="show 17 new notifications" color="inherit">
                    <Badge badgeContent={17} color="error">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                </div> */}
                {/* <div className={classes.sectionMobile}>
                  <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <MoreIcon />
                  </IconButton>
                </div>
                {renderMobileMenu}
                {renderMenu} */}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </div>
  )
}

export default Navbar