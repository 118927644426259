import React, { useContext, useEffect, useState } from "react"
import MUIDataTable from "mui-datatables"
import { Controller, useFormContext } from "react-hook-form"
import Button from '@material-ui/core/Button'
import CircularProgress from "@material-ui/core/CircularProgress"
import Fab from "@material-ui/core/Fab"
import Tooltip from "@material-ui/core/Tooltip"
import AddIcon from "@material-ui/icons/Add"
import TextField from "@material-ui/core/TextField"
import { SiniestroContext } from "../../context/SiniestroContext"
import { GestionContext } from "../../context/GestionContext"
import useCheckAbogado from "../../api/CheckAbogado"
import useGetUserLegacyId from "../../api/GetUserLegacyIdFromToken"
import useSasFetch from "../../api/SasConnection"
import { DangerButton } from "../utils/Buttons"


export default function Gestion(props) {
  const tipo = props.tipo

  const { siniestro, setSiniestro } = useContext(SiniestroContext)
  const idSiniestro = siniestro?.sro_id
  const { gestion, setGestion } = useContext(GestionContext)
  const { register, getValues, control, reset } = useFormContext()

  const [isLoading, setIsLoading] = useState(true)
  // bool[] en cada posicion dice cuales rows estan en modo edit.
  const [editable, setEditable] = useState([])
  const [isAdding, setIsAdding] = useState(false)
  const [propietarios, setPropietarios] = useState([])
  const esAbogado = useCheckAbogado()
  const legacyUserId = useGetUserLegacyId()
  const SasFetch = useSasFetch()

  useEffect(() => {
    const getPropietarios = async () => {
      const res = await SasFetch.get("/datossiniestro")
      console.log("res propietarios", res.data)
      setPropietarios(res.data.datossiniestro.propietarios)
    }
    getPropietarios()
  }, [])

  useEffect(() => {
    if (gestion[tipo] && !isAdding) {
      // inicializo todo en falso los editables, cosa que arranque readonly
      setEditable(prevEditable => {
        let arrayEditable = [...prevEditable]
        for (let i = 0; i < gestion[tipo].length; i++) {
          arrayEditable[i] = false
        }
        return arrayEditable
      })
    }
  }, [gestion, isAdding, tipo])

  useEffect(() => {
    // si ya cargo o es nuevo
    if (gestion[tipo] || !siniestro.sro_id)
      setIsLoading(false)

    // unmounting => save
    return () => {
      const values = getValues()
      setSiniestro((prevSiniestro) => {
        return { ...prevSiniestro, ...values }
      })
    }
  }, [idSiniestro, tipo, gestion, getValues, setSiniestro, siniestro?.sro_id])


  const handleAdd = () => {
    setIsAdding(true)
    const fechahora = (new Date()).toISOString()
    setGestion((prevGestion) => {
      return {
        ...prevGestion,
        [tipo]: [...prevGestion[tipo], { gon_fechahora: fechahora, gon_usuario: legacyUserId }]
      }
    })
    // setEditable true para este
    setEditable(prevEditable => {
      return [...prevEditable, true]
    })
  }

  const toggleEdit = (index) => {
    if (editable[index]) {
      const datos = getValues()
      setGestion(prevGestion => {
        const nuevaGestion = { ...prevGestion }
        nuevaGestion[tipo][index] = { ...nuevaGestion[tipo][index], ...datos.gestion[tipo][index] }
        return nuevaGestion
      })
    } else {
      reset({ ...siniestro, gestion })
    }
    setEditable(prevEditable => {
      let arrayEditable = [...prevEditable] // evitar mutacion con spread op
      // arrayEditable[index] = !arrayEditable[index]
      arrayEditable[index] = false // NO PERMITIR EDITAR.
      return arrayEditable
    })
  }

  const handleDelete = (index) => {
    setGestion(prevGestion => {
      let nuevaGestion = { ...prevGestion }
      nuevaGestion[tipo].splice(index, 1)
      return nuevaGestion
    })
    setEditable(prevEditable => {
      const nuevoEditable = [...prevEditable]
      nuevoEditable.splice(index, 1)
      return nuevoEditable
    })
  }

  const columns = [
    {
      name: 'gon_descripcion',
      label: "Descripción",
      options: {
        filter: false,
        customBodyRenderLite: dataIndex => {
          if (editable[dataIndex]) {
            return (
              <Controller
                name={`gestion.${tipo}[${dataIndex}].gon_descripcion`}
                control={control}
                inputRef={register}
                render={({ ref, value, onChange }) => (
                  <TextField
                    onChange={onChange}
                    value={value ?? ""}
                    inputRef={ref}
                    fullWidth
                  />
                )}
              />
            )
          } else {
            if (gestion[tipo][dataIndex]) { // este if es porque no existe ?[] en js aun
              return (
                <>{gestion[tipo][dataIndex].gon_descripcion}</> // en la TablaPersonas se hace con ?.
              )
            }
          }
        }
      }
    },
    {
      name: 'gon_usuario',
      label: "Usuario",
      options: {
        filter: true,
        customBodyRenderLite: dataIndex => {
          const usuario = gestion[tipo][dataIndex]?.gon_usuario_cbo_usuario
          if (usuario) {
            return (
              <>{usuario?.uio_segundonombre + ", " + usuario?.uio_primernombre}</>
            )
          } else {
            const propietario = propietarios.find(p => p.uio_id === legacyUserId)
            return (
              <>{(propietario?.uio_segundonombre || "Cargando") + ", " + (propietario?.uio_primernombre || " espere...")}</>
            )
          }
        }
      }
    },
    {
      label: "Fecha de Creación",
      name: "gon_fechahora",
      options: {
        filter: false,
        sort: true,
        sortDirection: 'desc',
        customBodyRenderLite: dataIndex => {
          if (gestion[tipo][dataIndex] && Date.parse(gestion[tipo][dataIndex].gon_fechahora)) {
            return (
              <>{new Date(Date.parse(gestion[tipo][dataIndex].gon_fechahora)).toLocaleString("es-AR", { dateStyle: "medium", timeStyle: "short" })}</>
            )
          }
        }
      }
    },
    {
      name: "Editar",
      options: {
        width: 300,
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <Button variant="contained" color="secondary" onClick={() => toggleEdit(dataIndex)} disabled={!esAbogado || !editable[dataIndex]}>
              {editable[dataIndex] ? 'Guardar' : 'Editar'}
            </Button>
          )
        }
      }
    },
    {
      name: "Eliminar",
      options: {
        width: 300,
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <DangerButton variant="contained" onClick={() => handleDelete(dataIndex)} disabled={!esAbogado}>
              Eliminar
            </DangerButton>
          )
        }
      }
    },
  ];


  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'vertical',
    selectableRows: 'none',
    customToolbar: () => {
      if (esAbogado) {
        return (
          <Tooltip title={"Agregar"}>
            <Fab style={{ marginLeft: 10, marginRight: -15 }} onClick={handleAdd} color="secondary" size="medium">
              <AddIcon />
            </Fab>
          </Tooltip>
        )
      } else return null
    },
    textLabels: {
      body: {
        noMatch: isLoading ?
          <CircularProgress /> :
          'No hay data.',
      },
    },
  };

  return (
    <GestionContext.Consumer>
      { ({ gestion }) => {
        return (
          <MUIDataTable title={'Gestión ' + (tipo === 'publica' ? 'Pública' : 'Privada')} data={gestion[tipo]} columns={columns} options={options} />
        )
      }}
    </GestionContext.Consumer>
  );

}