import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';


const palette = {
  common: { "black": "#000", "white": "#fff" },
  background: { "paper": "#fff", "default": "rgba(245, 245, 245, 1)" },
  primary: {
    light: "#718792",
    main: "#455a64",
    dark: "#1c313a",
    contrastText: "#fff"
  },
  secondary: {
    light: "#ffa270",
    main: "#ff7043",
    dark: "#c63f17",
    contrastText: "#fff"
  },
  error: {
    light: "#ff867c",
    main: "#ef5350",
    dark: "#b61827",
    contrastText: "#fff"
  },
  info: {
    main: "#00838F"
  },
  text: {
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "rgba(0, 0, 0, 0.54)",
    disabled: "rgba(0, 0, 0, 0.38)",
    hint: "rgba(0, 0, 0, 0.38)"
  }
}


const theme = createMuiTheme({
  palette,
  overrides: {
    MUIDataTableToolbar: {
      root: {
        backgroundColor: "#FDFDFD"
      }
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        backgroundColor: "#DADADA"
      }
    },
    MUIDataTableBodyRow: {
      root: {
        '&:nth-child(even)': { 
          backgroundColor: '#EFEFEF'
        }
      }
    },
  }
})


export default theme