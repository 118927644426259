import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';

// import Gpublica from './TabsSro/Gpublica';
import Gestion from './TabsSro/Gestion';
import DatosAccidente from './TabsSro/DatosAccidente';
import TablaPersonas from './TabsSro/TablaPersonas';
import ArchivosUpload from './TabsSro/ArchivosUpload';
import ArchivosTabla from './TabsSro/ArchivosTabla';
import useCheckAbogado from '../api/CheckAbogado';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    value === index && <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={0}>
        {children}
      </Box>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function TabsSiniestro() {
  const [value, setValue] = useState(0);
  const esAbogado = useCheckAbogado()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="tabs con info del siniestro" variant="scrollable" scrollButtons="auto">
          <Tab label="Gestión Pública" {...a11yProps(0)} value={0} />
          {esAbogado && <Tab label="Gestión Privada" {...a11yProps(1)} value={1} />}
          <Tab label="Damnificados" {...a11yProps(2)} value={2} />
          {/* <Tab label="Testigo" {...a11yProps(3)} /> */}
          <Tab label="Datos Accidente" {...a11yProps(3)} value={3} />
          <Tab label="Archivos Adjuntos" {...a11yProps(4)} value={4} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Gestion tipo="publica" />
      </TabPanel>
      {esAbogado && <TabPanel value={value} index={1}>
        <Gestion tipo="privada" />
      </TabPanel>}
      <TabPanel value={value} index={2}>
        <TablaPersonas tipo="damnificado" />
      </TabPanel>
      {/* <TabPanel value={value} index={3}>
        <TablaPersonas tipo="testigo" />
      </TabPanel> */}
      <TabPanel value={value} index={3}>
        <DatosAccidente />
      </TabPanel>
      <TabPanel value={value} index={4}>
        {esAbogado && <ArchivosUpload />}
        <ArchivosTabla />
      </TabPanel>
    </Paper>
  );
}