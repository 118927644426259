import React, { useEffect, useContext, useState } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import useSiniestrosConnection from "../api/SiniestrosConnection"
import { SiniestrosContext } from "../context/SiniestrosContext"
import CircularProgress from "@material-ui/core/CircularProgress"
import MUIDataTable from "mui-datatables"
import { BottomRightButton } from "../components/utils/Buttons"
import useCheckAbogado from "../api/CheckAbogado"
import useGetUserLegacyId from "../api/GetUserLegacyIdFromToken"


const SiniestrosLista = () => {
  const { siniestros, setSiniestros } = useContext(SiniestrosContext)
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()
  const { path } = useRouteMatch()
  const SiniestrosConnection = useSiniestrosConnection()

  const esAbogado = useCheckAbogado()
  const userLegacyId = useGetUserLegacyId()

  const columnsInit = [
    {
      label: 'ID',
      name: 'sro_id',
      options: {
        sortThirdClickReset: true,
        display: 'excluded',
        filter: false,
        sort: false,
        download: false
      }
    },
    {
      label: 'Expediente',
      name: 'expediente',
      options: {
        sortThirdClickReset: true,
        filter: false,
        sort: true,
        sortDirection: 'desc'
      }
    },
    {
      label: 'Nro',
      name: 'nro',
      options: {
        sortThirdClickReset: true,
        filter: false,
        sort: true,
      }

    },
    {
      label: 'Fecha de Creación',
      name: 'fechaCreacion',
      options: {
        sortThirdClickReset: true,
        filter: true,
        sort: true,
      }

    },
    {
      label: 'Fecha de Vencimiento',
      name: 'fechaVencimiento',
      options: {
        sortThirdClickReset: true,
        filter: true,
        sort: true,
      }

    },
    {
      label: 'PAS',
      name: 'pas',
      options: {
        sortThirdClickReset: true,
        filter: true,
        sort: true,
      }

    },
    {
      label: 'Propietario',
      name: 'propietario',
      options: {
        sortThirdClickReset: true,
        filter: true,
        sort: true,
      }

    },
    {
      label: 'Estado Tránsito',
      name: 'estado',
      options: {
        sortThirdClickReset: true,
        filter: true,
        sort: true,
      }

    },
    {
      label: 'Fecha del Accidente',
      name: 'fechaAccidente',
      options: {
        sortThirdClickReset: true,
        filter: true,
        sort: true,
      }

    },
    {
      label: 'Damnificado',
      name: 'damnificado',
      options: {
        sortThirdClickReset: true,
        filter: false,
        sort: true,
      }

    },
    {
      label: 'Compañia Aseguradora',
      name: 'aseguradora',
      options: {
        sortThirdClickReset: true,
        filter: true,
        sort: true,
      }

    },
    {
      label: 'Lesiones',
      name: 'lesiones',
      options: {
        sortThirdClickReset: true,
        filter: true,
        sort: true,
      }

    },
  ]

  const [columns, setColumns] = useState(columnsInit)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortOrder, setSortOrder] = useState({})

  useEffect(() => {
    const columnsStored = JSON.parse(localStorage.getItem('tableColumns'))
    const localPage = parseInt(localStorage.getItem('tablePage'))
    const localRowsPerPage = parseInt(localStorage.getItem('rowsPerPage'))
    const localSortOrder = JSON.parse(localStorage.getItem('columnSorting'))
    if (columnsStored) setColumns(columnsStored)
    if (localPage) setPage(localPage)
    if (localRowsPerPage) setRowsPerPage(localRowsPerPage)
    if (localSortOrder) setSortOrder(localSortOrder)
    console.log('STORE LOAD:', page, columns, rowsPerPage, sortOrder)
  }, [])

  useEffect(() => {
    localStorage.setItem('tableColumns', JSON.stringify(columns))
    localStorage.setItem('tablePage', JSON.stringify(page))
    localStorage.setItem('rowsPerPage', rowsPerPage)
    localStorage.setItem('columnSorting', JSON.stringify(sortOrder))
  }, [page, columns, rowsPerPage, sortOrder])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!esAbogado) {
          console.log('no es abogado es pas y llamo /pas', userLegacyId)
          const response = await SiniestrosConnection.get('/pas/' + userLegacyId)
          setSiniestros(response.data.data)
        } else {
          console.log('es abogado GET ALL')
          const response = await SiniestrosConnection.get()
          setSiniestros(response.data.data)
        }
        setIsLoading(false)
      } catch (e) {
        console.error('error en get api', e)
      }
    }
    if (userLegacyId)
      fetchData()
    else
      setIsLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [esAbogado, userLegacyId]);

  const handleClick = (siniestroRowData, rowMeta) => {
    history.push(path + '/' + siniestroRowData[0], siniestros[rowMeta.dataIndex])
  }
  const handleNewSiniestro = () => {
    const isNew = true
    // const latestSiniestro = tableData[0]?.sro_id + 1
    history.push(path + '/nuevo', isNew)
  }

  useEffect(() => {
    const data = (!siniestros.isLoading && siniestros.map((siniestro) => {
      try {
        var estado = siniestro?.sro_estadoactual_estado_siniestro?.estado_std?.std_nombre
        var propietario = [siniestro?.sro_usuario_cbo_usuario?.uio_segundonombre, siniestro?.sro_usuario_cbo_usuario?.uio_primernombre].filter(Boolean).join(', ')
        var aseguradora = siniestro?.sro_companiaaseguradora_sas_compania_aseguradora?.esa_razonsocial ?? ''
        var fechaAccidente = siniestro?.sas_datosaccidentes[0]?.dte_fechaaccidente?.split('T')[0]
        var lesiones = (siniestro?.sas_datosaccidentes[0]?.dte_lesiones ? 'Sí' : 'No')
        var pas = [siniestro?.sro_pas_sas_productor_asesor_seguro?.pas_usuario_cbo_usuario?.uio_segundonombre, siniestro?.sro_pas_sas_productor_asesor_seguro?.pas_usuario_cbo_usuario?.uio_primernombre].filter(Boolean).join(', ')
      } catch (e) {
        console.log('no carga la data', e)
      }
      return {
        sro_id: siniestro.sro_id,
        expediente: siniestro.sro_expedienteseq,
        nro: siniestro.sro_numero,
        fechaCreacion: siniestro.sro_fechacreacion.split('T')[0],
        fechaVencimiento: siniestro.sro_fechavencimiento?.split('T')[0],
        pas: pas,
        propietario: propietario,
        estado: estado,
        fechaAccidente: fechaAccidente,
        damnificado: siniestro.sro_damnificadosconcatenados,
        aseguradora: aseguradora,
        lesiones: lesiones,
      }
    }))
    console.log('seteo table data')
    setTableData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siniestros])

  const options = {
    filterType: 'dropdown',
    onFilterChange: (changedColumn, filterList) => {
      console.log('FILTER CHANGE:', changedColumn, filterList)
      var newCols = [...columns]
      for (var i = 0; i < newCols.length; i++) {
        if (newCols[i].name === changedColumn) {
          newCols[i].options.filterList = filterList[i];
        }
      }
      console.log('COLUMNS:', newCols)
      setColumns(newCols);
    },
    onChangePage: (currentPage) => {
      console.log('PAGE CHANGE:', currentPage)
      setPage(currentPage)
    },
    page: page,
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows)
    },
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 25, 50, 100],
    onColumnSortChange: (name, direction) => {
      console.log('SORT CHANGE:', name, direction)
      setSortOrder({ name, direction })
    },
    sortOrder: sortOrder,
    selectableRows: 'none',
    onRowClick: (rowData, rowMeta) => handleClick(rowData, rowMeta),
    textLabels: {
      body: {
        noMatch: isLoading ?
          <CircularProgress /> :
          'No hay data.',
      },
    },

  }

  return (
    <div>

      <MUIDataTable
        title={"Siniestros"}
        data={tableData}
        columns={columns}
        options={options}
      />

      {esAbogado &&
        <BottomRightButton variant="contained" color="primary" onClick={handleNewSiniestro} disabled={isLoading}>Nuevo</BottomRightButton>
      }

    </div>
  )
}

export default SiniestrosLista