import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useRef } from "react"

const NAMESPACE = "https://sastransito.com.ar/roles" // auth0 role namespace


export default function useCheckAbogado() {
  const { user, isAuthenticated } = useAuth0()
  const esAbogado = useRef(false)

  useEffect(() => {
    if (isAuthenticated)
      esAbogado.current = user[NAMESPACE].indexOf("abogado") !== -1
    console.log('esAbogado?', esAbogado)
  }, [user, isAuthenticated])

  return esAbogado.current
}
