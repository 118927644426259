import React, { useState, createContext } from 'react'

export const SiniestrosContext = createContext()

export const SiniestrosContextProvider = props => {
  const [siniestros, setSiniestros] = useState([], { isLoading: true })

  return (
    <SiniestrosContext.Provider value={{ siniestros, setSiniestros }}>
      {props.children}
    </SiniestrosContext.Provider>
  )
}