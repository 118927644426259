import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardDatePicker } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Controller, useFormContext } from "react-hook-form";
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { SiniestroContext } from '../../context/SiniestroContext'
import useSasConnection from '../../api/SasConnection'
import useCheckAbogado from '../../api/CheckAbogado';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    '& > *': {
      padding: theme.spacing(5),
    },
  },

}));


export default function DatosAccidente() {
  const { setSiniestro } = useContext(SiniestroContext)
  const classes = useStyles();
  const [tiposAccidente, setTiposAccidente] = useState([])
  const { register, getValues, control } = useFormContext();

  const SasConnection = useSasConnection()
  const esAbogado = useCheckAbogado()

  useEffect(() => {
    const populateTipoAccidente = async () => {
      try {
        const res = await SasConnection.get('/tiposaccidente')
        setTiposAccidente(res.data.tiposaccidente)
        // console.log('respuesta populate', res)
      } catch (e) {
        console.error('error al popular tipos de accidente', e)
      }
    }
    populateTipoAccidente()
    // unmounting => save
    return () => {
      const values = getValues()
      setSiniestro((prevSiniestro) => {
        return { ...prevSiniestro, ...values }
      })
    }
  }, [getValues, setSiniestro, SasConnection])


  return (
    <Paper className={classes.root}>
      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <Controller
            name="sas_datosaccidentes[0].dte_tipoaccidente"
            control={control}
            inputRef={register}
            render={({ ref, value, onChange }) => (
              <TextField
                inputRef={ref}
                select
                label="Tipo de Accidente"
                value={value ?? ""}
                onChange={(e) => {
                  onChange(e)
                }}
                variant="outlined"
                InputProps={{ readOnly: !esAbogado}}
                fullWidth
              >
                {tiposAccidente?.map((option) => (
                  <MenuItem key={option.tte_id} value={option.tte_id}>
                    {option.tte_nombre}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Controller
            control={control}
            name="sas_datosaccidentes[0].dte_fechaaccidente"
            inputRef={register}
            render={({ ref, value, onChange }) => (
              <KeyboardDatePicker
                inputRef={ref}
                format="DD/MM/YYYY"
                value={value ?? null}
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Fecha del Accidente"
                onChange={(e) => {
                  onChange(e)
                }}
                KeyboardButtonProps={{
                  "aria-label": "cambiar fecha de accidente",
                }}
                disabled={!esAbogado}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormControlLabel
            inputRef={register}
            label="Lesiones"
            control={
              <Controller
                name="sas_datosaccidentes[0].dte_lesiones"
                control={control}
                render={(props) => (
                  <Checkbox
                    {...props}
                    inputRef={props.ref}
                    checked={props.value ?? false}
                    onChange={(e) => {
                      props.onChange(e.target.checked)
                    }}
                    color="primary"
                    disabled={!esAbogado}
                  />
                )}
              />
            }
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Controller
            inputRef={register}
            name="sas_datosaccidentes[0].dom_calle"
            control={control}
            render={({ ref, value, onChange }) => (
              <TextField
                label="Calle"
                variant="outlined"
                value={value ?? ""}
                inputRef={ref}
                fullWidth
                onChange={(e) => {
                  onChange(e)
                }}
                InputProps={{ readOnly: !esAbogado}}
              />
            )}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Controller
            inputRef={register}
            name="sas_datosaccidentes[0].dom_numero"
            control={control}
            render={({ ref, value, onChange }) => (
              <TextField
                label="Número"
                variant="outlined"
                type="number"
                value={value ?? ""}
                inputRef={ref}
                fullWidth
                onChange={(e) => {
                  onChange(e)
                }}
                InputProps={{ readOnly: !esAbogado}}
              />
            )}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Controller
            inputRef={register}
            name="sas_datosaccidentes[0].dom_localidad"
            control={control}
            render={({ ref, value, onChange }) => (
              <TextField
                label="Localidad"
                variant="outlined"
                value={value ?? ""}
                inputRef={ref}
                fullWidth
                onChange={(e) => {
                  onChange(e)
                }}
                InputProps={{ readOnly: !esAbogado}}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            inputRef={register}
            name="sas_datosaccidentes[0].dte_hecho"
            control={control}
            render={({ ref, value, onChange }) => (
              <TextField
                label="Hecho"
                variant="outlined"
                multiline
                rows={4}
                value={value ?? ""}
                inputRef={ref}
                fullWidth
                onChange={(e) => {
                  onChange(e)
                }}
                InputProps={{ readOnly: !esAbogado}}
              />
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
