import React from "react"
import { Route } from "react-router-dom"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core"


const useStyles = makeStyles(() => ({
  parent: {
    position: "relative"
  },
  loading: {
    position: "absolute",
    top: "50%",
    left: "48%",
    // transform: "translate(-50%, -50%)" // bug weird movement
  }
}))

export default function PrivateRoute({ component, ...args }) {
  const classes = useStyles()

  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => {
          return (
            // <div className={classes.parent}>
              <CircularProgress className={classes.loading} />
            // </div>
          )
        }
      })}
      {...args}
    />
  )
}
