import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { BottomRightButton } from './Buttons'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles((theme) => ({
  danger: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  dialogActions: {
    justifyContent: 'center',
    padding: theme.spacing(3)
  }
}))

export default function DeleteButtonDialog(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleEliminar = () => {
    setOpen(false)
    props.delete()
  }

  return (
    <div>
      <BottomRightButton variant="contained" className={classes.danger} onClick={handleClickOpen} disabled={!props.canDelete} style={props.style}>
        Eliminar
      </BottomRightButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Eliminar Siniestro?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta operación no es reversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant="contained" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" className={classes.danger} onClick={handleEliminar} autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}