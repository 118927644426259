import React, { useContext, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Input from "@material-ui/core/Input"
import useSiniestrosConnection from "../../api/SiniestrosConnection"
import { SiniestroContext } from "../../context/SiniestroContext"


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    "& > *": {
      padding: theme.spacing(5),
    },
  },
  uploadButton: {
    height: theme.spacing(7),
  },
}))


export default function ArchivosUpload() {
  const classes = useStyles()
  const { siniestro, setSiniestro } = useContext(SiniestroContext)
  const { register, control, getValues } = useFormContext()
  const [adjunto, setAdjunto] = useState(null)
  const SiniestrosConnection = useSiniestrosConnection()

  const handleUpload = (e) => {
    const descripcion = getValues().ato_descripcion

    const objetoAdjuntos = {
      ato_descripcion: descripcion,
      ato_fechacreacion: new Date().toISOString(),
      ato_nombre: adjunto.name,
      ato_siniestro: siniestro.sro_id
    }
    setSiniestro((prevSiniestro) => {
      const adjuntosArray = [...prevSiniestro.sas_adjuntos, objetoAdjuntos]
      return { ...prevSiniestro, sas_adjuntos: adjuntosArray }
    })

    let formData = new FormData()
    formData.append('sas_adjunto', adjunto)
    formData.append('datos_adjunto', JSON.stringify(objetoAdjuntos))

    try {
      SiniestrosConnection.post('/adjunto', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then((response) => {
        objetoAdjuntos.ato_id = response.data.data.ato_id
      })
    } catch (e) {
      console.log('error al handleUpload')
    }
  }

  return (
    <Paper className={classes.root}>
      <Grid container spacing={3}>
        <Grid item sm={3}>
          <Input
            name={`sas_adjunto-${siniestro.sro_id}`}
            className={classes.uploadButton}
            margin="dense"
            type="file"
            onChange={(e) => setAdjunto(e.target.files[0])} // siempre [0] un solo archivo a la vez.
          >
            Seleccionar Archivo
            </Input>
        </Grid>
        <Grid item sm={8}>
          <Controller
            defaultValue={""}
            inputRef={register}
            name="ato_descripcion"
            control={control}
            render={({ ref, value, onChange }) => (
              <TextField
                label="Descripción"
                variant="outlined"
                value={value}
                inputRef={ref}
                fullWidth
                onChange={(e) => {
                  onChange(e)
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={1}>
          <Button
            className={classes.uploadButton}
            style={{ float: "right" }}
            variant="contained"
            color="secondary"
            type="button"
            onClick={(e) => {
              e.preventDefault()
              handleUpload(e)
              e.stopPropagation()
            }}
          >
            Subir
            </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}
