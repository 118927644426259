import React, { useContext, useEffect, useState } from "react"
import MUIDataTable from "mui-datatables"
import { Controller, useFormContext } from "react-hook-form"
import Button from '@material-ui/core/Button'
import CircularProgress from "@material-ui/core/CircularProgress"
import Fab from "@material-ui/core/Fab"
import Tooltip from "@material-ui/core/Tooltip"
import AddIcon from "@material-ui/icons/Add"
import TextField from "@material-ui/core/TextField"
import { SiniestroContext } from "../../context/SiniestroContext"
import useCheckAbogado from "../../api/CheckAbogado"
import { DangerButton } from "../utils/Buttons"


export default function TablaPersonas(props) {
  // esto esta por si se usa el mismo componente para damnificados y testigos
  // en una primera implementacion este componente solo se usa para damnificados
  const tipo = props.tipo

  const { siniestro, setSiniestro } = useContext(SiniestroContext)
  const [isLoading, setIsLoading] = useState(true)
  // bool[] en cada posicion dice cuales rows estan en modo edit.
  const [editable, setEditable] = useState([])
  const { register, getValues, control } = useFormContext()
  const esAbogado = useCheckAbogado()

  useEffect(() => {
    // inicializo todo en falso los editables, cosa que arranque readonly
    setEditable(prevEditable => {
      let arrayEditable = [...prevEditable]
      for (let i = 0; i < siniestro.sas_damnificados.length; i++) {
        arrayEditable[i] = false
      }
      return arrayEditable
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (siniestro.sas_damnificados.length === 0)
      setIsLoading(false)
  }, [siniestro])

  const handleAdd = () => {
    setSiniestro((prevSiniestro) => {
      const otherValues = getValues() // este es por si es nuevo, evita que te borre los campos del siniestro al agregar un damnificado sin guardar.
      const damnificadosArray = [...prevSiniestro.sas_damnificados, {}]
      return { ...prevSiniestro, ...otherValues, sas_damnificados: damnificadosArray }
    })
    console.log('add!', editable)
    // seteditable true para este
    setEditable(prevEditable => {
      const nuevoEditable = [...prevEditable, true]
      return nuevoEditable
    })
  }

  const toggleEdit = (index) => {
    console.log('editable toggle', editable)
    if (editable[index]) {
      setSiniestro(prevSiniestro => {
        const datos = getValues()
        console.log('getValues.sas_damnificados', datos.sas_damnificados[index])
        const nuevosDamnificados = [...prevSiniestro.sas_damnificados]
        nuevosDamnificados[index] = { ...nuevosDamnificados[index], ...datos.sas_damnificados[index] }
        console.log('nuevosDamnificados', nuevosDamnificados)
        return { ...prevSiniestro, sas_damnificados: nuevosDamnificados }
      })
    }
    setEditable(prevEditable => {
      let arrayEditable = [...prevEditable] // evitar mutacion con spread op
      arrayEditable[index] = !arrayEditable[index]
      return arrayEditable
    })
  }

  const handleDelete = (index) => {
    console.log('delete damnificado', index)
    setSiniestro(prevSiniestro => {
      const nuevosDamnificados = [...prevSiniestro.sas_damnificados]
      nuevosDamnificados.splice(index, 1)
      return { ...prevSiniestro, sas_damnificados: nuevosDamnificados }
    })
    setEditable(prevEditable => {
      const nuevoEditable = [...prevEditable]
      nuevoEditable.splice(index, 1)
      return nuevoEditable
    })
  }

  const columns = [
    {
      name: 'ddo_nombre',
      label: "Nombre",
      options: {
        filter: false,
        customBodyRenderLite: dataIndex => {
          if (editable[dataIndex]) {
            return (
              <Controller
                name={`sas_damnificados[${dataIndex}].ddo_nombre`}
                control={control}
                inputRef={register}
                render={({ ref, value, onChange }) => (
                  <TextField
                    onChange={onChange}
                    value={value ?? ""}
                    inputRef={ref}
                    fullWidth
                  />
                )}
              />
            )
          } else {
            return (
              <>{siniestro.sas_damnificados[dataIndex]?.ddo_nombre}</>
            )
          }
        }
      },
    },
    {
      name: 'ddo_apellido',
      label: "Apellido",
      options: {
        filter: true,
        customBodyRenderLite: dataIndex => {
          if (editable[dataIndex]) {
            return (
              <Controller
                name={`sas_damnificados[${dataIndex}].ddo_apellido`}
                control={control}
                inputRef={register}
                render={({ ref, value, onChange }) => (
                  <TextField
                    onChange={onChange}
                    value={value ?? ""}
                    inputRef={ref}
                    fullWidth
                  />
                )}
              />
            )
          } else {
            return (
              <>{siniestro.sas_damnificados[dataIndex]?.ddo_apellido}</>
            )
          }
        }
      }
    },
    // {
    //   name: "tipodocumento",
    //   label: "Tipo Doc.",
    //   options: {
    //     filter: false,
    //   }
    // },
    {
      name: 'numerodocumento',
      label: "Nro. de Documento",
      options: {
        filter: false,
        customBodyRenderLite: dataIndex => {
          if (editable[dataIndex]) {
            return (
              <Controller
                name={`sas_damnificados[${dataIndex}].numerodocumento`}
                control={control}
                inputRef={register}
                render={({ ref, value, onChange }) => (
                  <TextField
                    inputProps={{
                      type: "number", min: 0, max: 10
                    }}
                    onChange={onChange}
                    value={value ?? ""}
                    inputRef={ref}
                    fullWidth
                  // type="number"

                  // InputProps={{ type: "number", min: 0, max: 10, maxLength: 11 }}
                  />
                )}
              />
            )
          } else {
            return (
              <>{siniestro.sas_damnificados[dataIndex]?.numerodocumento}</>
            )
          }
        }
      }
    },
    {
      name: 'dom_calle',
      label: "Dirección",
      options: {
        filter: false,
        customBodyRenderLite: dataIndex => {
          if (editable[dataIndex]) {
            return (
              <Controller
                name={`sas_damnificados[${dataIndex}].dom_calle`}
                control={control}
                inputRef={register}
                render={({ ref, value, onChange }) => (
                  <TextField
                    onChange={onChange}
                    value={value ?? ""}
                    inputRef={ref}
                    fullWidth
                  />
                )}
              />
            )
          } else {
            return (
              <>{siniestro.sas_damnificados[dataIndex]?.dom_calle}</>
            )
          }
        }
      }
    },
    {
      name: 'provincia',
      label: 'Provincia',
      options: {
        filter: true,
        customBodyRenderLite: dataIndex => {
          if (editable[dataIndex]) {
            return (
              <Controller
                name={`sas_damnificados[${dataIndex}].provincia`}
                control={control}
                inputRef={register}
                render={({ ref, value, onChange }) => (
                  <TextField
                    onChange={onChange}
                    value={value ?? ""}
                    inputRef={ref}
                    fullWidth
                  />
                )}
              />
            )
          } else {
            return (
              <>{siniestro.sas_damnificados[dataIndex]?.provincia}</>
            )
          }
        }
      }
    },
    {
      name: 'ddo_telefono',
      label: 'Teléfono',
      options: {
        filter: true,
        customBodyRenderLite: dataIndex => {
          if (editable[dataIndex]) {
            return (
              <Controller
                name={`sas_damnificados[${dataIndex}].ddo_telefono`}
                control={control}
                inputRef={register}
                render={({ ref, value, onChange }) => (
                  <TextField
                    onChange={onChange}
                    value={value ?? ""}
                    inputRef={ref}
                    fullWidth
                  />
                )}
              />
            )
          } else {
            return (
              <>{siniestro.sas_damnificados[dataIndex]?.ddo_telefono}</>
            )
          }
        }
      }
    },
    {
      name: 'ddo_mail',
      label: 'Mail',
      options: {
        filter: true,
        customBodyRenderLite: dataIndex => {
          if (editable[dataIndex]) {
            return (
              <Controller
                name={`sas_damnificados[${dataIndex}].ddo_mail`}
                control={control}
                inputRef={register}
                render={({ ref, value, onChange }) => (
                  <TextField
                    onChange={onChange}
                    value={value ?? ""}
                    inputRef={ref}
                    fullWidth
                  />
                )}
              />
            )
          } else {
            return (
              <>{siniestro.sas_damnificados[dataIndex]?.ddo_mail}</>
            )
          }
        }
      }
    },
    {
      name: 'editar',
      label: 'Editar',
      options: {
        width: 300,
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <Button variant="contained" color="secondary" onClick={() => toggleEdit(dataIndex)} disabled={!esAbogado}>
              {editable[dataIndex] ? 'Guardar' : 'Editar'}
            </Button>
          )
        }
      }
    },
    {
      name: 'eliminar',
      label: 'Eliminar',
      options: {
        width: 300,
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <DangerButton variant="contained" onClick={() => handleDelete(dataIndex)} disabled={!esAbogado}>
              Eliminar
            </DangerButton>
          )
        }
      }
    }
  ];

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'vertical',
    selectableRows: 'none',
    customToolbar: () => {
      if (esAbogado) {
        return (
          <Tooltip title={"Agregar"}>
            <Fab style={{ marginLeft: 10, marginRight: -15 }} onClick={handleAdd} color="secondary" size="medium">
              <AddIcon />
            </Fab>
          </Tooltip>
        )
      } else return null
    },
    // onRowClick: (rowData, rowMeta) => handleClick(rowData, rowMeta),
    textLabels: {
      body: {
        noMatch: isLoading ?
          <CircularProgress /> :
          'No hay data.',
      },
    },
  };

  return (
    <SiniestroContext.Consumer>
      {({ siniestro }) => {
        return (
          <MUIDataTable title={(tipo === 'damnificado' ? 'Damnificados' : 'Testigos')} data={siniestro.sas_damnificados} columns={columns} options={options} />
        )
      }}
    </SiniestroContext.Consumer>
  );

}