import React, { useState, useContext, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { KeyboardDatePicker } from "@material-ui/pickers"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { Controller, useFormContext } from "react-hook-form"
import MenuItem from "@material-ui/core/MenuItem"
import { SiniestroContext } from "../context/SiniestroContext"
import useSasFetch from "../api/SasConnection"
import useCheckAbogado from "../api/CheckAbogado"


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    "& > *": {
      // margin: theme.spacing(1),
      padding: theme.spacing(5),
    },
    marginBottom: theme.spacing(2),
  },
}))

export default function DatosSiniestro() {
  const { setSiniestro } = useContext(SiniestroContext)
  // console.log('del contexto a datossiniestro', siniestro)
  const classes = useStyles()

  const SasFetch = useSasFetch()
  const esAbogado = useCheckAbogado()

  // POPULADORES
  const [propietarios, setPropietarios] = useState([])
  const [pass, setPass] = useState([])
  const [estadosTransito, setEstadosTransito] = useState([])
  const [companiasAseguradoras, setCompaniasAseguradoras] = useState([])

  const { register, getValues, control } = useFormContext()

  useEffect(() => {
    const populateDatosSiniestro = async () => {
      try {
        const res = await SasFetch.get("/datossiniestro")
        // console.log("RESPUESTA DEL DATOS SINIESTRO", res)
        const datossiniestro = res.data.datossiniestro
        setPropietarios(datossiniestro.propietarios)
        setPass(datossiniestro.pass)
        setEstadosTransito(datossiniestro.estadosTransito)
        setCompaniasAseguradoras(datossiniestro.companiasAseguradoras)
      } catch (err) {
        console.error("error populando datos del siniestro", err)
      }
    }
    populateDatosSiniestro()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      const values = getValues()
      setSiniestro((prevSiniestro) => {
        return { ...prevSiniestro, ...values }
      })
    }
  }, [getValues, setSiniestro])

  return (
    <Paper className={classes.root}>
      <Grid container spacing={3}>
        <Grid item sm={3} xs={12}>
          <Controller
            control={control}
            inputRef={register}
            name="sro_expedienteseq"
            render={({ ref, value, onChange }) => (
              <TextField
                inputRef={ref}
                label="Expediente"
                variant="outlined"
                value={value ?? ""}
                InputProps={{ readOnly: true }}
                type="number"
                onChange={(e) => {
                  onChange(e)
                }}
                fullWidth
                disabled
              />
            )}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Controller
            name="sro_numero"
            control={control}
            inputRef={register}
            render={({ ref, value, onChange }) => (
              <TextField
                label="Número"
                variant="outlined"
                onChange={(e) => {
                  onChange(e)
                }}
                value={value ?? ""}
                inputRef={ref}
                InputProps={{ readOnly: !esAbogado }}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Controller
            name="sro_usuario"
            control={control}
            inputRef={register}
            render={({ ref, value, onChange }) => (
              <TextField
                select
                label="Propietario"
                value={value ?? ""}
                onChange={(e) => {
                  onChange(e)
                }}
                variant="outlined"
                inputRef={ref}
                InputProps={{ readOnly: !esAbogado }}
                fullWidth
                required
                error={!value}
              >
                {propietarios?.map((option) => (
                  <MenuItem key={option.uio_id} value={option.uio_id}>
                    {[option.uio_segundonombre, option.uio_primernombre].filter(Boolean).join(", ") || option.uio_login}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Controller
            control={control}
            inputRef={register}
            name="sro_pas"
            render={({ ref, value, onChange }) => (
              <TextField
                select
                label="PAS"
                value={value ?? ""}
                onChange={(e) => {
                  onChange(e)
                }}
                inputRef={ref}
                variant="outlined"
                InputProps={{ readOnly: !esAbogado }}
                fullWidth
              >
                {pass?.map((option) => {
                  if (option.pas_usuario_cbo_usuario == null) {
                    // el pas no tiene usuario
                    return (
                      <MenuItem key={option.pna_id} value={option.pna_id}>
                        {
                          [option.pas_apellido, option.pas_nombre].filter(Boolean).join(", ") || <span style={{color: "gray"}}>(sin nombre)</span>
                        } 
                      </MenuItem>
                    )
                  } else {
                    // el pas tiene un usuario
                    return (
                      <MenuItem
                        key={option.pas_usuario_cbo_usuario.uio_id}
                        value={option.pna_id}
                      >
                        {[option.pas_usuario_cbo_usuario.uio_segundonombre, option.pas_usuario_cbo_usuario.uio_primernombre].filter(Boolean).join(", ") || option.pas_usuario_cbo_usuario.uio_login}
                      </MenuItem>
                    )
                  }
                })}
              </TextField>
            )}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Controller
            control={control}
            name="sro_fechavencimiento"
            inputRef={register}
            render={({ ref, value, onChange }) => (
              <KeyboardDatePicker
                inputRef={ref}
                format="DD/MM/YYYY"
                value={value ?? null}
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Fecha de Vencimiento"
                onChange={(e) => {
                  onChange(e)
                }}
                KeyboardButtonProps={{
                  "aria-label": "Fecha de vencimiento",
                }}
                disabled={!esAbogado}
                fullWidth
                required
                error={!value}
              />
            )}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Controller
            name="sro_estadoactual_estado_siniestro.estado_std_id"
            control={control}
            inputRef={register}
            render={({ ref, value, onChange }) => (
              <TextField
                select
                label="Estado Tránsito"
                value={value ?? ""}
                onChange={(e) => {
                  onChange(e)
                }}
                inputRef={ref}
                variant="outlined"
                InputProps={{ readOnly: !esAbogado }}
                fullWidth
                required
                error={!value}
              >
                {estadosTransito?.map((option) => (
                  <MenuItem key={option.std_id} value={option.std_id} >
                    {option.std_nombre}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Controller
            name="sro_companiaaseguradora"
            control={control}
            inputRef={register}
            render={({ ref, value, onChange }) => (
              <TextField
                select
                label="Compañía Aseguradora"
                value={value ?? ""}
                onChange={(e) => {
                  onChange(e)
                }}
                inputRef={ref}
                variant="outlined"
                InputProps={{ readOnly: !esAbogado }}
                fullWidth
                required
                error={!value}
              >
                {companiasAseguradoras?.map((option) => (
                  <MenuItem key={option.pna_id} value={option.pna_id}>
                    {option.esa_razonsocial}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="sro_descripcioncorta"
            control={control}
            inputRef={register}
            render={({ ref, value, onChange }) => (
              <TextField
                label="Descripción"
                variant="outlined"
                multiline
                rows={4}
                onChange={(e) => {
                  onChange(e)
                }}
                value={value ?? ""}
                inputRef={ref}
                InputProps={{ readOnly: !esAbogado }}
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
