import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useRef } from "react"

const NAMESPACE = "https://sastransito.com.ar/app_metadata" // auth0 role namespace


export default function useGetUserLegacyId() {
  const { user, isAuthenticated } = useAuth0()
  const legacyId = useRef()

  useEffect(() => {
    if (isAuthenticated)
      legacyId.current = user[NAMESPACE]["uio_id"]
    console.log('legacyId?', legacyId.current)
  }, [user, isAuthenticated])

  return legacyId.current
}
