// import React, { useState, createContext } from 'react'
import { createContext } from 'react'

export const SiniestroContext = createContext()

// export const SiniestroContextProvider = props => {
//   const [siniestro, setSiniestro] = useState([], { isLoading: true })

//   return (
//     <SiniestroContext.Provider value={{ siniestro, setSiniestro }}>
//       {props.children}
//     </SiniestroContext.Provider>
//   )
// }