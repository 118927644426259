import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core'

const BottomRightButton = withStyles((theme) => ({
  root: {
    float: 'right',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1.5)
  }
}))(Button)

const BottomLeftButton = withStyles((theme) => ({
  root: {
    float: 'left',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1.5)
  }
}))(Button)

const DangerButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
}))(Button)

export { BottomLeftButton, BottomRightButton, DangerButton }