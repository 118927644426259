import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => (
  {
    boton: {
      marginLeft: theme.spacing(3),
      '&:hover': {
        textDecoration: 'none',
      }
    },
    link: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      }
    }
  }
))

export default function MenuLink(props) {
  const classes = useStyles()
  return (
    <Link to={props.to} className={classes.link}>
      <Button variant={props.variant} className={classes.boton} color={props.color}>
        {props.children}
      </Button>
    </Link>
  )
}

MenuLink.propTypes = {
  color: PropTypes.string
}