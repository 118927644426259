import React, { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import theme from '../../theme'
import { SiniestroContext } from "../../context/SiniestroContext";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import useSiniestrosConnection from "../../api/SiniestrosConnection";
import DeleteButtonDialog from "../utils/DeleteButtonDialog";
import useCheckAbogado from "../../api/CheckAbogado";


export default function ArchivosTabla() {
  const { siniestro, setSiniestro } = useContext(SiniestroContext)
  const [isLoading, setIsLoading] = useState(true)
  const SiniestrosConnection = useSiniestrosConnection()
  const esAbogado = useCheckAbogado()

  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiPaper: {
        root: {
          marginTop: 10
        },
        elevation4: {
          boxShadow: 'none',
        }
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#EAEAEA"
        }
      },
    }
  })

  useEffect(() => {
    if (siniestro.sas_adjuntos.length === 0)
      setIsLoading(false)
  }, [siniestro])


  const columns = [
    {
      name: 'ato_nombre',
      label: "Nombre del Archivo",
      options: {
        filter: false,
      }
    },
    {
      name: 'ato_descripcion',
      label: "Descripción",
      options: {
        filter: true,
      }
    },
    {
      name: "ato_fechacreacion",
      label: "Fecha de Creación",
      options: {
        filter: false,
      }
    },
    {
      name: "Descargar",
      options: {
        width: 300,
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <Button variant="contained" style={{ backgroundColor: theme.palette.info.main, color: '#FFF' }} onClick={() => { handleDownload(dataIndex) }}>
              Descargar
            </Button>
          );
        }
      }
    },
    {
      name: "Eliminar",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <DeleteButtonDialog variant="contained" style={{ backgroundColor: theme.palette.error.main, margin: 0, float: 'left' }} canDelete={esAbogado} delete={() => { handleDelete(dataIndex) }}>
              Eliminar
            </DeleteButtonDialog>
          );
        }
      }
    },
  ];

  const handleDownload = (dataIndex) => {
    SiniestrosConnection.get("/adjunto/" + siniestro.sro_id + "/" + siniestro.sas_adjuntos[dataIndex].ato_id, { responseType: 'blob' })
      .then((response) => {
        const link = document.createElement("a")
        link.href = window.URL.createObjectURL(response.data)
        link.download = siniestro.sas_adjuntos[dataIndex].ato_nombre
        document.body.appendChild(link)
        link.click()
      })
  }

  const handleDelete = (dataIndex) => {
    SiniestrosConnection.delete("/adjunto/" + siniestro.sro_id + "/" + siniestro.sas_adjuntos[dataIndex].ato_id)
    setSiniestro((prevSiniestro) => {
      const adjuntosArray = [...prevSiniestro.sas_adjuntos]
      adjuntosArray.splice(dataIndex, 1)
      return { ...prevSiniestro, sas_adjuntos: adjuntosArray }
    })
  }

  const options = {
    filter: false,
    responsive: 'vertical',
    selectableRows: 'none',
    sortOrder: {
      name: 'ato_fechacreacion',
      direction: 'desc'
    },
    textLabels: {
      body: {
        noMatch: isLoading ?
          <CircularProgress /> :
          'No hay data.',
      },
    },
  };

  return (
    <MuiThemeProvider theme={getMuiTheme}>
      <SiniestroContext.Consumer>
        {({ siniestro }) => {
          return (
            <MUIDataTable title={'Archivos Adjuntos'} data={siniestro.sas_adjuntos} columns={columns} options={options} />
          )
        }}
      </SiniestroContext.Consumer>
    </MuiThemeProvider>
  );

}