import React from 'react'
import Navbar from './Navbar'

const Header = () => {
  return (
    // <div>
    //   <h1 className="font-weight-light display-1 text-center">SAS</h1>
    // </div>
    <Navbar />
  )
}

export default Header