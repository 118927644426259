import { useAuth0 } from "@auth0/auth0-react";
import Container from "@material-ui/core/Container";
import React from "react";
import { useHistory } from "react-router";

const Home = () => {
  const { isAuthenticated, isLoading } = useAuth0()
  const history = useHistory()
  console.log('HISTORY', history)

  if (isAuthenticated) {
    history.push("/siniestros")

  }
  return (
    <Container style={{ textAlign: "center" }}>
      <img src={process.env.PUBLIC_URL + "/logo512.png"} alt={"SAS logo"} style={{ maxWidth: "100%" }} />
      <h1 style={{ fontFamily: "Source Serif Pro, serif" }}>Sistema de Administración de Siniestros de Tránsito</h1>
      <p>Bienvenido/a, para comenzar debe iniciar sesión.</p>
    </Container>
  )
}

export default Home